import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import "../sass/global.scss"
import Analytics from "./Analytics"

// declare global {
//   interface Window {
//     example: string
//   }
// }

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const PageLayout = ({ children, view }: any) => {
  useEffect(() => {
    if (view) {
      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", "ViewContent")
        }
      }
    }
  }, [view])
  return (
    <>
      {/* <Analytics /> */}
      <Helmet>
        <script
          type="text/javascript"
          src="https://www.bugherd.com/sidebarv2.js?apikey=falvzstacyqx3jyidmxddg"
          async="true"
        ></script>
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        ></link>

        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '3213553365593139');
          ${view ? "fbq('track', 'ViewContent');" : ""}
          fbq('track', 'PageView');
          `,
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=3213553365593139&ev=PageView&noscript=1"
        />`,
          }}
        ></script> */}
      </Helmet>

      <Header></Header>
      {children}
      <Footer></Footer>
    </>
  )
}

export { PageLayout }
