import styled from "styled-components"

interface ButtonBasicProps {
  ma?: string
}

const ButtonBasic = styled.button<ButtonBasicProps>`
min-width: 200px;
border: none;
border-radius: 10px;
font-family: 'Open Sans', sans-serif;
color: white;
background-color: #aba17b;
font-size: 16px;
text-transform: uppercase;
line-height: 1.7em;
padding: 0.8em 2em;
margin: ${(props) => props.ma};
cursor: pointer;
&:hover {
  background-color: #313131;
  ::after {
    opacity: 1;
    margin-left: 0.5em;
  }
}
a {
  color: #fff;
  text-decoration: none;
}
@media all and (max-width: 1280px) {
  font-size: 15px;
}
`

interface ActionButtonProps {
  ma?: string
}

const ActionButton = styled.button<ActionButtonProps>`
  border: none;
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  color: white;
  background-color: #aba17b;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.7em;
  padding: 0.8em 2em;
  margin: ${(props) => props.ma};
  cursor: pointer;
  &:hover {
    background-color: #313131;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  @media all and (max-width: 1280px) {
    font-size: 15px;
  }
`

const InverseButton = styled.button`
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  padding: 20px 24px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
  a {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    background-color: #fff;
    color: #000;
    a {
      color: #000;
    }
  }
`
export { ButtonBasic, ActionButton, InverseButton }
